@import '../../../styles/mixins.scss';

.slider {
  width: 100%;
  position: relative;
  margin: 0 -10px 68px;

  &__imgWrap {
    position: relative;
    float: none;
    margin: 0;
    transition: opacity 0.3s ease;
    display: block;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #eee;
    height: 260px;
    width: auto;
    z-index: 1;
    &:before {
      content: ' ';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 70px;
      height: 98px;
      background: url('/icons/book-no-cover.svg') no-repeat 50%;
      background-size: cover;
      z-index: 0;
    }

    &:hover {
      opacity: 0.8;
    }
  }
  &__img {
    position: relative;
    display: inline-block;
    vertical-align: top;
    max-width: none;
    width: auto;
    color: transparent;
  }
  &__title {
    color: #106edc;
    font-size: 16px;
    line-height: 20px;
    text-decoration: none;
    display: block;
    margin: 12px 0 2px 0;
    margin-bottom: 2px;
    transition: opacity 0.3s ease;
    &:hover {
      opacity: 0.8;
    }
  }
  &__author {
    font-size: 16px;
    line-height: 20px;
    color: #c5c5c5;
    transition: opacity 0.3s ease;
    &:hover {
      opacity: 0.8;
    }
  }
}

.authors {
  margin: 0 0 58px;
  &__item {
    display: inline-block;
    padding: 10px 15px;
    margin: 0 10px 10px 0;
    font-size: 16px;
    line-height: 18px;
    color: #106edc;
    background: #f5f5f5;
    border-radius: 5px;
    transition: opacity 0.3s ease;
    &:hover {
      opacity: 0.8;
    }
    &_all {
      display: inline-block;
      padding: 10px 15px;
      margin: 0 0 10px 0;
      font-size: 16px;
      line-height: 18px;
      background: #f5f5f5;
      color: #000000;
      border: 1px solid #c4c4c6;
      border-radius: 3px;
      transition: opacity 0.3s ease;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.lastReviews {
  margin: 0 -14px 68px;
  @include max-screen-480 {
    margin: 0 -10px 68px;
  }
  &__item {
    width: 240px;
    height: auto;
    font-size: 14px;
    @include max-screen-480 {
      width: 300px;
    }
  }
  &__card {
    display: flex;
    align-items: flex-start;
    margin-bottom: 12px;
  }
  &__cardImg {
    width: 68px;
    height: auto;
    margin-right: 10px;
    img {
      position: relative;
      width: 100%;
      height: auto;
      display: block;
    }
  }
  &__cardLink {
    position: relative;
    z-index: 1;
    display: block;
    line-height: 1.2;
    max-height: 90px;
    background: #f4f4f4;
    border: 1px solid rgba(0, 0, 0, 0.1);
    overflow: hidden;
    &:before {
      content: ' ';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 33px;
      height: 46px;
      background: url('/icons/book-no-cover.svg') no-repeat 50%;
      background-size: cover;
      z-index: 0;
    }
  }
  &__cardDesc {
    width: calc(100% - 68px);
  }
  &__book {
    position: relative;
    z-index: 1;
    display: inline-block;
    line-height: 1.2;
    color: #106edc;
    transition: opacity 0.3s ease;
    margin-bottom: 3px;
    &:hover {
      opacity: 0.8;
    }
  }
  &__text {
    line-height: 20px;
  }
  &__author {
    margin-bottom: 3px;
  }
  &__user {
    font-weight: bold;
    margin-bottom: 3px;
  }
  &__date {
    color: #9d9c9f;
    margin-bottom: 12px;
  }
  &__btn {
    color: #106edc;
    transition: opacity 0.3s ease;
    &:hover {
      opacity: 0.8;
    }
  }
}

// .books {
//   margin: 0 -5px;
//   display: flex;
//   flex-wrap: wrap;
//   margin-bottom: 30px;

//   &__item {
//     display: flex;
//     padding: 0 5px;
//     width: 360px;
//     flex: 0 1 360px;
//     margin-bottom: 40px;
//     @include max-screen-1366 {
//       width: 350px;
//       flex: 0 1 350px;
//     }
//     @include max-screen-768 {
//       margin-bottom: 20px;
//     }
//     @include max-screen-380 {
//       margin-bottom: 45px;
//     }
//   }
//   &__img {
//     width: 123px;
//     text-align: center;
//     margin: 0 10px 0 0;
//     a {
//       display: block;
//       color: #106edc;
//       transition: opacity 0.3s ease;
//       &:hover {
//         opacity: 0.8;
//       }
//     }
//     img {
//       width: 123px;
//       height: auto;
//       border: 1px solid rgba(0, 0, 0, 0.1);
//     }
//   }
//   &__desc {
//     overflow: hidden;
//     font-size: 14px;
//     line-height: 20px;
//   }
//   &__name {
//     a {
//       display: block;
//       color: #106edc;
//       transition: opacity 0.3s ease;
//       &:hover {
//         opacity: 0.8;
//       }
//     }
//   }
//   &__type {
//     margin-bottom: 5px;
//     color: #9d9c9f;
//   }
//   &__rating {
//     margin-bottom: 5px;
//   }
//   &__text {
//     line-height: 20px;
//   }

// }
