@import './../../../styles/mixins.scss';

.bookCardRating {
  &_small {
    .bookCardRating__star {
      width: 12px;
      height: 12px;
    }
    .bookCardRating__txt {
      font-size: 13px;
      margin-left: 3px;
    }
  }
  &_top {
    .bookCardRating__star {
      width: 16px;
      height: 16px;
      @include max-screen-480 {
        width: 14px;
        height: 14px;
      }
    }
    .bookCardRating__txt {
      font-size: 16px;
      @include max-screen-480 {
        font-size: 14px;
      }
    }
  }
  &__star {
    position: relative;
    left: -2px;
    width: 13px;
    display: inline-block;
    width: 14px;
    height: 14px;
    margin: 0 2px;
    background-image: url('/icons/star-grey-icon.svg');
    background-repeat: no-repeat;
    background-size: cover;
    &_active {
      background-image: url('/icons/star-orange-icon.svg');
    }
  }
  &__txt {
    display: inline-block;
    font-size: 14px;
    line-height: 1;
    color: #000;
    margin-left: 4px;
    position: relative;
    top: -1.5px;
  }
}
