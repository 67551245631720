@import '../../../styles/mixins.scss';

/* Карточка книги */
.bookCardItem {
  display: flex;
  padding: 0 5px;
  width: 480px;
  flex: 0 1 480px;
  margin-bottom: 40px;
  @include max-screen-1366 {
    width: 350px;
    flex: 0 1 350px;
  }
  @include max-screen-480 {
    width: 100%;
    flex: 0 1 100%;
  }

  &_short {
    width: 360px;
    flex: 0 1 360px;
    margin-bottom: 40px;
    @include max-screen-1366 {
      width: 350px;
      flex: 0 1 350px;
    }
  }

  &_long {
    width: 480px;
    flex: 0 1 480px;
    .bookCardItem__img {
      width: 192px;
      @include max-screen-480 {
        width: 123px;
      }

      & a {
        &:before {
          width: 78px;
          height: 108px;
        }
      }
      & img {
        width: 190px;
        @include max-screen-480 {
          width: 123px;
        }
      }
    }
    .bookCardItem__type {
      margin-bottom: 3px;
      color: #000;
    }
  }

  &__img {
    width: 123px;
    text-align: center;
    margin: 0 10px 0 0;
    color: transparent;
    a {
      position: relative;
      background: #f4f4f4;
      border: 1px solid rgba(0, 0, 0, 0.1);
      display: block;
      transition: opacity 0.3s ease;
      &:hover {
        opacity: 0.8;
      }
      &:before {
        content: ' ';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 58px;
        height: 80px;
        background: url('/icons/book-no-cover.svg') no-repeat 50%;
        background-size: cover;
        z-index: 0;
      }
    }
    img {
      position: relative;
      width: 123px;
      height: auto;
      display: block;
    }
  }
  &__desc {
    overflow: hidden;
    font-size: 14px;
    line-height: 20px;
  }
  &__author {
    font-size: 12px;
    margin-bottom: 3px;
    a {
      color: #666;
      transition: opacity 0.3s ease;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  &__name {
    a {
      display: block;
      color: #106edc;
      transition: opacity 0.3s ease;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  &__type {
    margin-bottom: 5px;
    color: #9d9c9f;
  }
  &__rating {
    margin-bottom: 5px;
  }
  &__text {
    line-height: 20px;
  }
  &__year {
    font-size: 13px;
    color: #999;
    margin: -5px 0 8px;
  }
  &__fullVersion {
    position: absolute;
    z-index: 1;
    background-color: #ff1a74;
    color: white;
    font-weight: bold;
    font-size: 13px;
    left: 1px;
    bottom: 10px;
    padding: 0 5px;
  }
  &__date {
    font-size: 13px;
    color: #999;
    margin: 0 0 8px;
  }
  &__genre {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 16px;
  }
}
